<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Registrazione Attività</ion-title>
      </ion-toolbar>
      <meta http-equiv="Cache-control" content="no-cache">
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
        </ion-toolbar>
      </ion-header>
      <div id="container"> 
        <div class="attivitanuova">
          <ion-item id="cliente">
            <ion-label>Cliente</ion-label>
            <ion-select id="selcliente" placeholder="Seleziona il cliente" v-model="account">
                <ion-select-option :value="a.id" v-for="a,i in accounts" :key="'account-'+i">{{a.name}}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item id="rifofferta"  v-if="account!=''">
            <ion-label>Rif. Offerta</ion-label>
            <ion-input v-model="task.ref_off" placeholder="Inserire il riferimento offerta..."></ion-input>
          </ion-item>
          <ion-item id="titolofferta"  v-if="account!=''">
            <ion-label>Titolo</ion-label>
            <ion-input v-model="task.title" placeholder="Inserire il titolo offerta..."></ion-input>
          </ion-item>
          <ion-input type="hidden" value="" id="verificaInizio"></ion-input>
          <ion-button type="submit" @click="inizioAttivita" v-if="account!=''" value="1" v-model='startAttivita'>
            Inizio attività
          </ion-button>
        </div>
        <br>
        <div>
          <h2 style="margin:5%;">Attualmente attive:</h2>
            <dtable       
            id="attive"   
            color="#fff"
            bgcolor="rgb(56,128,255)"
            :heading="taskLogHeading"
            :data="tasks"
            :total="table.total"
            :perPage="table.perPage"
            :currentPage="table.currentPage"
            :orderBy="table.orderBy"
            :order="table.order"
            @changePage="getActiveTask"
          >
            <template v-slot:account="slotProps">
                {{slotProps.row.account_name}} 
            </template>
            <template v-slot:btns="slotProps">
                <ion-button @click="fineAttivita(slotProps.row.account,slotProps.row.id,slotProps.row.ref_off);">
                  TERMINA
                </ion-button>
              </template>
          </dtable>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonButtons,
    IonButton,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonMenuButton,
    IonPage, 
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    toastController 
    } from '@ionic/vue';
    import Dtable from './partials/Dtable'

  export default {
    name: 'Folder',
    components: {
      IonButtons,
      IonButton,
      IonContent,
      IonHeader,
      IonInput,
      IonItem,
      IonLabel,
      IonMenuButton,
      IonPage,
      IonSelect,
      IonSelectOption,
      IonTitle,
      IonToolbar,
      Dtable
    },
    data: function(){
      return {
        flagAttivita:'',
        account:{
          name:'',
          km:0,
          travel_cost:0,
        },
        startAttivita:'',
        accounts:[],
        companies:[],
        selectedOption:'',
        task:{
          id:0,
          account:'',
          company:'',
          title:'',
          begindate:'',
          consultant:'',
          status:'',
          ref_off:'',
        },
        taskLogHeading: [
          {
            text: 'Data Inizio',
            code: 'begindate'
          },
          {
            text: 'Cliente',
            code: 'account'
          },
          {
            text: 'Rif. Offerta',
            code: 'ref_off'
          },
          {
            text: '',
            code: 'btns'
          }
        ],
        tasks:[],
        table: {
          currentPage: 1,
          total: '',
          perPage: 0,
          orderBy: 'begindate',
          order: 'asc',
        },
      }
    },
    methods:{
      async inizioAttivita() {
        if(this.flagAttivita!="inizio"){
          this.axios.post(this.apiUrl + 'apri-task', {account:this.account, task:this.task, orderBy:'name', order:'asc'}, this.headers)
          .then(async (response) => {
            if(response.data.status_code==200){ 
              const toast = await toastController
              .create({
                color: 'success',
                message: response.data.message,
                duration: 2000
              })
              this.flagAttivita = "inizio";
              window.location.reload();
              return toast.present();
            }
          })
        }
      },
      async fineAttivita(account,id,ref_off) {
        const toast = await toastController
        .create({
          color: 'danger',
          message: 'Attività terminata',
          duration: 2000,
        })
        this.$router.push('/folder/fine-attivita/'+account+'/'+id+'/'+ref_off);
        return toast.present();
      },
      getAccounts(){
        this.axios.post(this.apiUrl + 'get-accounts-list', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.accounts=response.data.accounts              
          }
        })
      },
      getLastTask(){
        this.axios.post(this.apiUrl + 'get-last-task', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.task=response.data.task              
          }
        })
      },
      getActiveTask(obj = false){
        if(obj) {
          this.table.currentPage = obj.page
          this.table.orderBy = obj.orderBy
          this.table.order = obj.order
        }
        this.axios.post(this.apiUrl + 'get-active-task', { page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order, search:this.search }, this.headers)
        .then(async (response) => {
          this.tasks = response.data.tasks.data
          this.table.total = response.data.tasks.total
          this.table.perPage = response.data.tasks.per_page
        })
      },
      getAccountInfo(){
        this.axios.post(this.apiUrl + 'get-account-info', {id:this.$route.params.cliente}, this.headers)
          .then(async (response) => {
            this.account=response.data.account;
          })  
      }
    },
    ionViewWillEnter: function(){
      this.getAccounts();
      this.getAccountInfo();
      this.getActiveTask();
      this.flagAttivita="";
    }
  }
</script>

<style scoped>
  @media only screen and (max-width: 400px) {
    #container{
      min-height:60%;
      display:flex;
      align-items:center;
      justify-content:center;
      flex-wrap:wrap;
      margin-top:5%;
    }
  }

  #container{
    min-width:95%;
  }

  #container ion-item{
    justify-content: center;
  }

  #attive{
    width:90%;
    margin:5%
  }
</style>